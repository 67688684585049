

.erservices-container {
  & { padding: 15px; height: 100%; width: 100%; }

  .header-section{
    & {background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 7px 2%;}
    .title { margin: 10px 0;  font-size: 26px; font-weight: 800; text-transform: uppercase;  color: #535c68; font-family: 'proxima-nova', sans-serif; }
    .header-items-div { display: flex; align-items: center; gap: 20px; }
    
    .text-input { display: flex; align-items: center; gap: 10px; border-bottom: 1px solid #757575; padding: 5px 0; font-size: 14px; width: 250px; }
    .text-input input { border: none; outline: none; font-size: 16px; flex-grow: 1; color: #555; }
    .text-input svg { color: #3920f9; font-size: 20px; }
    
    .deliva-btn-bordered { background-color: #f5f5f5; border: none; padding: 8px 16px; border-radius: 6px; font-size: 14px; font-weight: bold; color: #333; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); cursor: pointer; transition: background-color 0.3s; }
    
    .deliva-btn-bordered:hover {
      background-color: #ebebeb;
    }
    
    .add-service-button { background-color: #4441ee; color: white; padding: 8px 20px; border: none; border-radius: 6px; font-size: 14px; font-weight: bold; cursor: pointer; transition: background-color 0.3s; }
    
    .add-service-button:hover {
      background-color: #357abd;
    }    
    .filter-button{font-weight: 800; font-family: 'proxima-nova', sans-serif; white-space: nowrap; font-size: 14px; text-transform: uppercase;}
  }

  .status-active {
    & { position: relative; padding: .25rem 1.25rem; display: flex; width: fit-content; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #dff0de; color: #329c3a; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #329c3a; }
  }
  .status-invite {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #fff2d7; color: #f9b104; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #f9b104; }
  }
  .status-pending {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center;width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #d4e7ff; color: #0472f9; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #0472f9; }
  }
  .status-suspended {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }
  .status-draft {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: lightgrey; color: #fff; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #a2a5a7; }
  }
  .status-unknown {
    & { position: relative;padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }

  .erservices-table {
    & { height: calc(100vh - 180px); border-radius: 0px; position: relative; }
    .erservices-table-body {
      & { }

      .erservices-table-row {
        &{} 
        
        .erservices-table-cell{
          & { border-bottom: none !important;  font-weight: 400 !important; font-family: 'proxima-nova', sans-serif !important; }
          .erservicesName {
            & { display: flex; align-items: center; gap: .5rem; }
            img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover;}
          }
          
          .er-type-value { 
            & { position: relative; padding: 5px 8px; display: flex; align-items: center; justify-content: start; gap: 10px; border-radius: 5px; height: 25px; width: 98px; text-transform: capitalize; }
            &.local { background: #f1c40f; color: #ffffff; }
            &.regional { background: #1abc9c; color: #ffffff; }
            &::before { content: ""; position: relative; left: 4px; width: 7px; height: 7px; border-radius: 50%; margin-right: 5px; }
            &.local::before { background: #f39c12; }
            &.regional::before { background: #16a085; }
          }
          // .icons-container{
          //   display: flex; width: fit-content; gap: 8px;
          //   .button-icon{width: 20px !important; padding: 0; margin: 0;}
          // }

          .couponDiv{
            &{border-radius: 5px;  border: 1px solid #e3e3e7;  position: relative;  width: 75px;  text-align: center;  padding: 4px 15px;  gap:4px;  padding-bottom: 0;  font-size: 14px; display: flex; align-items: center;}
            // img{ height: 16px; width: 20px;}
            .couponImg{width:13px;}
            .mapIcon{ width: 10px;}
          }
        }
        .er-title { width: 200px; padding: 0; padding-left: 10px }
        .er-company { flex: 1; }
        .er-service-name { width: 120px; }
        .company-logo {
          & { width: 50px; padding: 0; padding-left: 10px; }
          img { height: 32px; width: 32px; border-radius: 100%; object-fit: cover; }
        }
        .tools { width: 80px; }
      }
    }
    .updating-row { opacity: 0.5; pointer-events: none; }
  }
}


.erservices-table-head { background-color: #f3f4fb;}
.erservices-head-cell { background-color: #f3f4fb !important; font-size: 15px !important;  color: #9294a3 !important; font-family: 'proxima-nova', sans-serif !important; text-transform: capitalize !important; font-weight: 600 !important; padding: 10px !important; }

.grey-btn { background: #f1f2f6 !important;  color: #535c68 !important;    margin: 0 !important;  min-width: 64px !important;  line-height: 36px !important;  padding: 0 16px !important;  border-radius: 4px !important; overflow: visible !important;}

.blue-button{ font-family: 'proxima-nova', sans-serif;;  color: #fff; background-color: #2b1de8 !important; text-transform: capitalize !important; border-radius: 6px !important; padding: 8px 34px !important; height: 38px !important; }

.loader-container { position: fixed; top: 0; left: 0; display: flex; justify-content: center; align-items: center; height: 100vh; width: 100vw; z-index: 1000; pointer-events: none; }
.loader { color: #576574; size: 40px; }

/* Modal Container */
.custom-modal .MuiPaper-root { background-color: white; border-radius: 8px; min-width: 500px; box-shadow: none; overflow: hidden; }
.modal-header { background-color: #1a202c; color: white; font-weight: 700; padding: 20px; font-size: 20px; text-align: center; }
.modal-content { padding: 24px; font-size: 18px; color: black; text-align: center; margin: 15px; }
.modal-actions { display: flex; justify-content: center; padding: 20px; background-color: #f1f3f5; border-top: none; gap: 20px; }
.modal-button { font-weight: 600; padding: 10px 24px; border: none; cursor: pointer; border-radius: 6px; outline: none; box-shadow: none; font-size: 16px; }
.modal-button.no { background-color: transparent; color: #6b7280; }
.modal-button.yes { background-color: transparent; color: #6b7280; }
.modal-button:hover { opacity: 0.8; }
.modal-button:focus, .modal-button:active { outline: none !important; border: none !important; box-shadow: none !important; }





