.drawer-container { display: flex; flex-direction: column; height: 100vh; width: 100%; background-color: #fff; overflow: hidden;
  .drawer { display: flex; flex-direction: column; height: 100%; overflow: hidden; }
  .textfields-container { display: flex; flex-direction: column; width: 50%; gap: 15px; }
  
  
  .layout-container { 
    & { display: flex; flex: 1; overflow: hidden; }
    .left-container { 
      & { width: 50%; height: 100vh; display: flex; flex-direction: column; position: relative; }
      .map-container { width: 100%; height: calc(100vh - 380px); overflow: hidden; }
      .form-container { 
        & { height: 380px; overflow-y: auto; padding: 10px; background: #fff; position: absolute; bottom: 0; width: 100%; }
        .locations-textfield { margin: 0; }
        section { height: auto; }
      }
    }
    .table-container { 
      & { width: 50%; flex-grow: 1; max-height: calc(100vh - 125px); overflow-y: auto; padding: 0; background: #fff; position: relative; }
      thead { position: sticky; top: 0; background-color: white; z-index: 2; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); }
      table { 
        & { width: 100%; border-collapse: collapse; table-layout: fixed; }
        th, td { text-align: left; }
      }
      .location-tbody { max-height: none; overflow-y: auto; flex-grow: 1; }
      .location-row { display: flex; align-items: center; overflow: hidden; }
      .location-col-title-address { 
        & { width: 100%; overflow: hidden; }
        .title-address-inline { 
          & { display: flex; flex-direction: column; align-items: flex-start; max-width: 100%; }
          .location-title { font-weight: bold; font-size: 14px; }
          .location-address { font-size: 12px; color: gray; }
        }
      }
      .location-col-coords { width: 120px; justify-content: center; text-align: center; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
      .actions { width: 10%; min-width: 80px; display: flex; justify-content: space-around; }
    }
  }
  .manage-location-buttons { display: flex; gap: 10px; margin-top: 15px; justify-content: flex-end;}
  .location-add-button { background-color: #1976d2; color: #fff; padding: 8px 15px; border-radius: 5px; cursor: pointer;
    &:hover {
      background-color: #1565c0;
    }
  }
  .reset-btn { background-color: #f44336; color: white; padding: 8px 15px; border-radius: 5px; cursor: pointer;
    &:hover {
      background-color: #d32f2f;
    }
  }
}


