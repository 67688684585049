.topic-drawer { position: fixed; top: 0; right: -450px; width: 800px; height: 100vh; background: white; box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2); transition: right 0.3s ease-in-out; z-index: 1301; display: flex; flex-direction: column;}
  
  .topic-drawer.open {
    right: 0;
  }
  
  .drawer-header { display: flex; justify-content: space-between; align-items: center; padding: 16px; background: #4a5568; color: white; }
  .drawer-content { flex-grow: 1; padding: 16px; display: flex; flex-direction: column; gap: 12px; }
  
  .drawer-footer { padding: 16px; display: flex; justify-content: space-between; background: #f8f9fa; border-top: 1px solid #ddd; }
  .cancel-button { background: #2d3748 !important; color: white !important; text-transform: none; padding: 8px 24px; }
  .save-button { background: #d1d5db !important;  color: #6b7280 !important; text-transform: none; padding: 8px 24px; }
  .btn-primary { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #1c2238 !important; border: 2px solid transparent;
    color: #fff !important; box-shadow: 0 0 5px rgba(189, 189, 189, 0.4745098039); border-radius: 5px; padding: 6px 8px; transition: 250msease-in-out; cursor: pointer;
  }
  .save-button:disabled { background: #e5e7eb !important;  color: #9ca3af !important; }
  