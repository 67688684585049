.tags-drawer {
    position: relative !important;
    top: 0;
    right: 0;
    width: 800px;
    max-width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 1100;
    background: white;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.2);
  
    .drawer-header {
      height: 60px;
      background: #535c68;
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: bold;
      padding: 0 20px;
      flex-shrink: 0;
    }
  
    .drawer-content {
      width: 800px; 
      flex: 1;
      overflow-y: auto;
      padding: 20px;
      background: white;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  
    .manage-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      background: white;
      height: 60px;
      flex-shrink: 0;
      border-top: 1px solid #ddd;
      
      button {
        min-width: 120px;
      }
      .cancel-button {
        background: #2d3748 !important; 
        color: white !important;
        text-transform: none;
        padding: 8px 24px;
      }
      
      .save-button {
        background: #d1d5db !important; 
        color: #6b7280 !important; 
        text-transform: none;
        padding: 8px 24px;
      }
      .btn-primary { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 600; background: #1c2238 !important; border: 2px solid transparent;
        color: #fff !important; box-shadow: 0 0 5px rgba(189, 189, 189, 0.4745098039); border-radius: 5px; padding: 6px 8px; transition: 250msease-in-out; cursor: pointer;
      }
      .save-button:disabled {
        background: #e5e7eb !important; 
        color: #9ca3af !important;
      }
    }
  
    @media (max-width: 768px) {
      width: 100vw;
    }
  }
  
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1300;
  }
  
  