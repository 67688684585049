
.companies-container {
  & { padding: 1rem; height: 100%; width: 100%; }

  // .header-section{
  //   & { background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 7px 2%;}
  //   .title { margin: 10px 0; font-size: 1.5em; font-weight: 800; text-transform: uppercase; color: #535c68; font-family: 'proxima-nova', sans-serif; }
  //   .header-items-div{ display: flex; align-items: center; gap: .5rem; width: 100%;}
  //   .filter-button{font-weight: 800; font-family: 'proxima-nova', sans-serif;; font-size: 14px; text-transform: uppercase;}
  // }

  .company-table-head { 
    & { background-color: #f3f4fb; }
    .company-head-cell { font-size: 13px; color: #9294a3 !important; font-family: 'proxima-nova', sans-serif; text-transform: uppercase !important; font-weight: 800 !important; }
  }

  .status-active {
    & { position: relative; padding: .25rem 1.25rem; display: flex; width: fit-content; align-items: center; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #dff0de; color: #329c3a; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #329c3a; }
  }
  .status-invite {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #fff2d7; color: #f9b104; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #f9b104; }
  }
  .status-pending {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center;width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #d4e7ff; color: #0472f9; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #0472f9; }
  }
  .status-suspended {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }
  .status-draft {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }
  .status-unknown {
    & { position: relative; padding: .25rem 1.25rem; display: flex; align-items: center; width: fit-content; justify-content: center; gap: 10px; border-radius: 5px; height: 25px; background: #ffced8; color: #dc143c; }
    &::before { content: ""; position: absolute; left: 8px; width: 7px; height: 7px; border-radius: 50%; top: calc(50% - 4px); background: #dc143c; }
  }

  .company-table {    
    & { height: 80%; }
    .company-table-body {
      & { }

      .company-table-row{
        &{} 
        
        .company-table-cell {
          & { border-bottom: none !important;  font-weight: 400 !important; }
          .companyName {
            display: flex; align-items: center; gap: .5rem;
            img{ height: 32px; width: 32px; border-radius: 100%; object-fit: cover;}
          }
          // .icons-container{
          //   display: flex; width: fit-content; gap: 8px;
          //   .button-icon{width: 20px !important; padding: 0; margin: 0;}
          // }

          .couponDiv{
            &{border-radius: 5px;  border: 1px solid #e3e3e7;  position: relative;  width: 75px;  text-align: center;  padding: 4px 15px;  gap:4px;  padding-bottom: 0;  font-size: 14px; display: flex; align-items: center;}
            // img{ height: 16px; width: 20px;}
            .couponImg{width:13px;}
            .mapIcon{ width: 10px;}
          }
        }
      }
    }
  }
  .agent-table-pagination {
    & { height: 10%; }
  }
}


// .company-table-head{ background-color: #f3f4fb; }
// .company-head-cell{ font-size: 1em !important;  color: #9294a3 !important; font-family: 'proxima-nova', sans-serif; text-transform: capitalize !important; font-weight: 600 !important; }

.grey-btn{ background: #f1f2f6 !important;  color: #535c68 !important;    margin: 0 !important;  min-width: 64px !important;  line-height: 36px !important;  padding: 0 16px !important;  border-radius: 4px !important; overflow: visible !important;}

.blue-button{ font-family: 'proxima-nova', sans-serif;  color: #fff; background-color: #2b1de8 !important; text-transform: capitalize !important; border-radius: 6px !important; padding: 8px 34px !important; height: 38px !important; }

// ManageCompanyDrawer.scss

/* Branding Tab Content */
.branding-tab-content {padding: 40px; background-color: #f4f4f4; text-align: center; height: 100vh;}

/* Logo Placeholder */
.branding-logo-placeholder { width: 100px; height: 100px; border-radius: 50%;background-color: #e0e0e0; display: flex; align-items: center; justify-content: center; overflow: hidden; margin: 0 auto 20px; border: 1px solid #ccc;}

.branding-logo-placeholder img { width: 60%;height: 60%;object-fit: contain; }

/* Note Text */
.branding-note { font-size: 14px; color: #666; margin-bottom: 20px; max-width: 600px; margin: 0 auto 20px; text-align: center;}

/* File Upload Section */
.branding-file-upload { display: flex; justify-content: center; align-items: center; gap: 10px; margin-bottom: 30px; }

.branding-file-upload button { text-transform: none; background-color: #f4f4f4; border: 1px solid #ccc; font-weight: bold;}

.branding-file-upload span { font-size: 14px; color: #666; }

/* Colour Input Field */
.branding-color-input { max-width: 100vw; margin: 0 auto; margin-bottom: 30px; padding: 10px; border-radius: 4px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); }

/* Buttons Section */
.branding-buttons { display: flex; justify-content: space-between; max-width: 100vw; margin: 14px auto; }

.branding-cancel-button { text-transform: none; color: #cf4949; border-width: 2px; }

.branding-update-button { text-transform: none; background-color: #f44336 !important; color: white; padding: 10px 20px;font-weight: bold; border-radius: 4px; }

.branding-update-button:hover { background-color: #d32f2f; }

/* Contacts Tab Content */
.tab-content { padding: 15px; background-color: #f4f4f4; height: 100vh; display: flex; flex-direction: column; justify-content: flex-start; }

.tab-content .MuiTextField-root .MuiInputBase-root { background-color: white; padding: 2px 4px; font-size: 14px; line-height: 1.2; height: 55px; }

.tab-content .MuiTextField-root label { font-size: 14px; color: #555; }

/* Typography for Note */
.note-text { font-size: 12px; color: #555; font-weight: bold; margin: 0; text-align: left; }


/* Textarea for Description */
.text-area { width: 100%; background-color: white; border: 1px solid #ccc; border-radius: 4px; padding: 8px; font-size: 14px; color: #333; box-sizing: border-box; resize: none; }

/* Buttons */
.button-container { display: flex; justify-content: space-between; align-items: center; width: 100%; padding: 10px 20px; box-sizing: border-box; }
/* Cancel Button */
.button-container .MuiButton-outlined { text-transform: none; color: #555; border: 1px solid #ccc; }

/* Save Changes Button */
.button-container .MuiButton-contained { text-transform: none; background-color: #f44336; color: white; font-weight: bold; padding: 8px 16px; border-radius: 4px; }

.button-container .MuiButton-contained:hover { background-color: #d32f2f; }

.notes-container { padding: 20px; font-family: Arial, sans-serif;
  .quill-editor { margin-bottom: 20px;
    .ql-toolbar { background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 4px; }
    .ql-container { height: 150px; border: 1px solid #ccc; border-radius: 4px; }
  }
  .add-note-button { background-color: #ff0000 !important; color: white !important; text-transform: none; padding: 10px 20px; border-radius: 4px; font-weight: bold; display: inline-block; margin-left: auto; display: block;
    &:hover { background-color: #cc0000 !important; }
  }
  .notes-list { margin-top: 20px;
    .note-item { display: flex; align-items: center; justify-content: space-between;
      background-color: #f9f9f9; border: 1px solid #ccc; border-radius: 4px; padding: 10px; margin-bottom: 10px;
      .note-content { flex: 1; margin-right: 10px; }
      .remove-note-icon { cursor: pointer; color: #ff0000;
        &:hover { color: #cc0000; }
      }        
    }
    .no-notes { text-align: center; color: #888; font-size: 14px; margin-top: 50px; }
  }
}
.status-message { position: fixed; bottom: 20px; left: 50%; transform: translateX(-50%); padding: 10px 20px; background-color: orange; color: white; border-radius: 5px; font-size: 0.9rem; text-align: center; z-index: 1000; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); opacity: 1; transition: opacity 0.3s ease-in-out;}