.topics-container { padding: 1rem; height: calc(100vh - 64px); width: 100%; display: flex; flex-direction: column; background-color: #f8f9fa;

  .header-section { background-color: white; border-radius: 10px; display: flex; justify-content: space-between; padding: 1rem 2rem;
    .title { font-size: 1.5em; font-weight: 800; text-transform: uppercase; color: #535c68; }
    .header-items-div { display: flex; align-items: center; gap: 0.5rem; }
  }
  .search-bar { width: 200px; border: 1px solid #ccc; border-radius: 6px; }
  .add-button { background-color: #2b1de8 !important; color: #fff !important; font-weight: 600; border-radius: 6px !important; padding: 8px 24px !important; }
  .topics-table-container { flex-grow: 1; background-color: white; width: 100%; border-radius: 10px; overflow: auto; }

  .topics-head-cell { font-size: 13px; color: #9294a3 !important; font-family: "proxima-nova", sans-serif; text-transform: uppercase !important; font-weight: 800 !important; background-color: #f3f4fb !important; padding: 12px; } 
  .topics-table-row { background: #ffffff !important; }
  .topics-table-cell { padding: 12px; background: #ffffff !important; }
  .action-buttons { display: flex; gap: 10px; }
  .star-active { color: gold; cursor: pointer; }
  .star-inactive { color: grey; cursor: pointer; }
  .star-column { text-align: center !important; }
  .star-cell { text-align: center !important; display: flex; justify-content: center; align-items: center; }
}
