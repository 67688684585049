.tags-container { padding: 1rem; height: calc(100vh - 64px); width: 100%; display: flex; flex-direction: column; background-color: #f8f9fa; }

.header-section {
   & { background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 1rem 2rem; }
  .title{  margin: 10px 0;  font-size: 1.5em; font-weight: 800; text-transform: uppercase;  color: #535c68; font-family: 'proxima-nova', sans-serif; }
  .header-items-div{ display: flex; align-items: center; gap: .5rem; border: none;}
}

.search-bar { width: 200px; border: 1px solid #ccc; border-radius: 6px; padding: 5px 10px; }

.add-button { background-color: #2b1de8 !important; color: #fff !important; text-transform: uppercase !important; border-radius: 6px !important; padding: 8px 24px !important; font-weight: 600; white-space: nowrap; }

.tags-table-container { flex-grow: 1; background-color: white; width: 100%; border-radius: 10px; padding: 0; overflow: auto; }
.tags-table { width: 100%; border-collapse: collapse; }

.tags-head-cell { font-size: 13px; color: #9294a3 !important; font-family: "proxima-nova", sans-serif; text-transform: uppercase !important; font-weight: 800 !important; background-color: #f3f4fb !important; padding: 12px; }

.tags-table-body .tags-table-row { background: #f3f4fb !important; }

.tags-table-body .tags-table-cell { border-bottom: 1px solid #e0e0e0 !important; font-weight: 400 !important; font-family: "proxima-nova", sans-serif; padding: 12px; background: #f3f4fb !important; }
.action-buttons { display: flex; justify-content: flex-end; align-items: center; gap: 10px; } 
.action-icon { font-size: 18px !important; color: #000 !important; cursor: pointer; }
.tags-pagination { background-color: white; padding: 1rem 2rem; border-radius: 0 0 10px 10px; flex-shrink: 0; }
