// ! These are global button styles. Please edit with caution. make new class for a specific button that doesn't fit the defined criteria 

.deliva-btn {
  & { white-space: nowrap; border: 2px solid #2b1de8; font-family: 'proxima-nova', sans-serif; text-transform: uppercase; display: inline-flex; align-items: center; justify-content: center; position: relative; background-color: #2b1de8; outline: 0; border: 2px solid #2b1de8; margin: 0; border-radius: 0; cursor: pointer; user-select: none; vertical-align: middle; text-decoration: none; color: inherit; font-weight: 600; padding: 8px; height: 40px; border-radius: 4px; transition: 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; color: #ffffff; }
  &:hover { text-decoration: none; background-color: #3629f2; border: 2px solid #2b1de8; }
  &:active { text-decoration: none; background-color: #2418cd; border: 2px solid #2b1de8; }
  &:disabled { text-decoration: none;  border: none; background-color: #d2d2d27a; color: #979797; cursor: default; pointer-events: all; }
}

.deliva-btn.cancel {
  & { background-color: #d2d2d27a; outline: 0; border: 2px solid #d2d2d27a; color: #979797; }
  &:hover { text-decoration: none; background-color: #979797; border: 2px solid #979797; color: #767676; }
  &:active { text-decoration: none; background-color: #979797; border: 2px solid #979797; color: #767676; }
  &:disabled { text-decoration: none;  border: none; background-color: #979797; color: #d2d2d27a; color: #767676; cursor: default; pointer-events: all; }
}

.deliva-btn-bordered {
  & { border: 2px solid #2b1de8; background: #FFFFFF; color: #2b1de8; }
  &:hover { text-decoration: none; background-color: #2b1de810; }
  &:active { text-decoration: none; background-color: #2b1de815; }
  &:disabled { text-decoration: none; border: 2px solid #979797; background-color: #d2d2d27a; color: #979797; cursor: default; pointer-events: all; }
}

.deliva-btn-filter {
  & { border: 0px solid #dcdfe6; background: #f4f4f5; color: #3b3e45; font-weight: 800; text-transform: uppercase; border-radius: 4px; padding: 0px 16px; display: inline-block; cursor: pointer;font-family: proxima-nova;}
  &:hover { background-color: #e0e2e6; text-decoration: none; border: 0px solid #dcdfe6; color: #535c68;}
  &:active { background-color: #d3d5d9; border: 0px;}
  &:disabled { border: 0px solid #e5e7eb; background-color: #f4f4f5; color: #9ca3af; cursor: default; pointer-events: none; }
}

.deliva-btn-extra-big {
  & { padding: 14px; font-size: 18px; }
}

.deliva-btn-big {
  & { padding: 12px; font-size: 16px; }
}

.deliva-btn-small {
  & { padding: 6px; font-size: 14px; }
}

.deliva-btn-extra-small {
  & { padding: 6px; font-size: 12px; }
}

.deliva-btn-full-width {
  & { width: 100%; }
}

.deliva-btn-half-width {
  & { width: 50%; }
}

.roundedFull {
  & { border-radius: 100%; min-height: 40px !important; min-width: 40px !important; padding: 0; }
  
}

.header-section {
  & {background-color: white; border-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; display: flex; width: 100%; align-items: center; justify-content: space-between; padding: 7px 2%;}
  .title { margin: 10px 0;  font-size: 26px; font-weight: 800; text-transform: uppercase;  color: #535c68; font-family: 'proxima-nova', sans-serif; }
  .header-items-div { 
    & { display: flex; align-items: center; gap: 20px; }
    .text-input { display: flex; align-items: center; gap: 10px; border-bottom: 1px solid #757575; padding: 5px 0; font-size: 14px; width: 250px; }
    .text-input input { border: none; outline: none; font-size: 16px; flex-grow: 1; color: #555; }
    .text-input svg { color: #3920f9; font-size: 20px; }
    .deliva-btn-bordered { background-color: #f5f5f5; border: none; padding: 8px 16px; border-radius: 6px; font-size: 14px; font-weight: bold; color: #333; box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); cursor: pointer; transition: background-color 0.3s; }
    .deliva-btn-bordered:hover { background-color: #ebebeb; }    
    .add-btn { background-color: #4441ee; color: white; padding: 8px 34px; border: none; border-radius: 6px; font-size: 14px; font-weight: bold; cursor: pointer; transition: background-color 0.3s; }
    .add-btn:hover {background-color: #357abd;}    
    .filter-btn {font-weight: 800; font-family: 'proxima-nova', sans-serif; white-space: nowrap; font-size: 14px; text-transform: uppercase;}
  }
}

.listing-table {
  & { height: calc(100vh - 180px); border-radius: 0px; position: relative; }
  
  .table-head { background-color: #f3f4fb;}
  .head-cell { background-color: #f3f4fb !important; font-size: 15px !important; color: #9294a3 !important; font-family: 'proxima-nova', sans-serif !important; text-transform: capitalize !important; font-weight: 600 !important; padding: 10px !important; }
  .table-cell { border-bottom: none !important; font-weight: 400 !important; font-family: "proxima-nova", sans-serif !important; }
  .counts-container {
    & { border-radius: 5px; position: relative; width: 75px; text-align: center; padding: 4px 15px; gap:4px; padding-bottom: 0; font-size: 14px; display: flex; align-items: center;}
    // img{ height: 16px; width: 20px;}
    img { width: 13px; }
    .mapIcon { width: 10px; }
  }
  .MuiCheckbox-root { padding: 0 9px; }
}


.MuiMenuItem-root { font-family: 'proxima-nova', sans-serif !important; font-size: 14px !important; }
.MuiOutlinedInput-input { font-family: 'proxima-nova', sans-serif !important;}
.MuiInput-input { font-family: 'proxima-nova', sans-serif !important; }
.MuiButton-root { font-family: 'proxima-nova', sans-serif !important; }

// ! These are global button styles. Please edit with caution. make new class for a specific button that doesn't fit the defined criteria 